import MainMerkle from "../merkle";
import { json, Link, NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import iconMenu from "../Images/icons/icons-menu.png";
import toggleSidebarSlice from "../reducers/toggleSidebar";
import { useNavigate } from "react-router-dom";
import walletIcon from "../Images/icons/icon-wallet.png"
import bethelLogo from "../Images/icons/bethel-logo.png"
import arrowUp from "../Images/icons/icons-arrow-up.png"
import arrowDown from "../Images/icons/icons8-down-100.png"
import copyIcon from "../Images/icons/icon-copy.png"
import robot from "../Images/BG-Images/robot.png"
import metaMaskLogo from "../Images/icons/metamask.png"
import validationIcon from "../Images/icons/validationFail.png"
import $u from "../utils/$u.js";
import { ethers } from "ethers";
import becxSlice from "../reducers/becxReduser.js";
import MetamaskInstall from "./Cards/MetamaskInstall.jsx";
import requireAvalibilitySlice from "../reducers/RequireAvalibilySlice.js";
import verifyIcon from "../Images/icons/icon-verify.png"
import loadingGif from "../Images/Animation-gifs/Rolling-1s-200px(1).gif"
import iconDone from "../Images/icons/icon-newdone.png"
import iconUpload from "../Images/icons/icon-cloud-upload.png"
import planStatusSlice from "../reducers/PlanStatusSlice.js";
import uploadGif from "../Images/Animation-gifs/upload.gif"
import uploadStatusSlice from "../reducers/UploadStatusSlice.js";
import uploadTriggerSlice from "../reducers/uploadTriggerSlice.js";
import iconClose from "../Images/icons/icons-close.png"
import MetamaskInstalationGuide from "./Cards/MetamaskInstalationGuide.jsx";
import mecx from "../Images/BG-Images/mxce2.png"
import bit from "../Images/BG-Images/bitmart.png"
import Token2049Coupon from "./Token2049Coupon.jsx";
import totalPointsSlice from "../reducers/totalPointsReducer.js";
import filePointsSlice from "../reducers/filePointsReducer.js";



const wc = require("../witness/wintess_calcutlator.js");
const fileStorageJSON = require("../json/FileStorage.json");
const snarkjs = require("snarkjs");
const fileStorageABI = fileStorageJSON.abi;
const fileStorageInterface = new ethers.utils.Interface(fileStorageABI);


function Navbar() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const ChainId = process.env.REACT_APP_BLOCK_CHAIN_ID

  const [toggleToken, setToggleToken] = useState(false)

  // smart contract data
  const fileStorageAddress = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS;  // contract address
  const fileStorageABI = fileStorageJSON.abi;

  const fileStorageJSON2 = require("../json/FileStorage2.json");
  const fileStorageAddress2 = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS_2
  const fileStorageABI2 = fileStorageJSON2.abi

  // get user did 
  const userDid = useSelector((state) => state.DidReducer)
  const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)

  // for the add user fucntions
  const [account, updateAccount] = useState(null);
  const [registerWalletAddress, setRegisterWalletAddress] = useState(null)
  const [polygonMainnnet, setPolygonMainnet] = useState(false)

  useEffect(() => {
    get_connected_wallet_addresses()
    metamaskConnect()
    getBECXcoinPrize() //get becx price 
    checkUserDidAvailable()
    checkPlanExpire() //check plan status 
    getTotalPoints() // get total points
  }, []);

  // cupon button switch
  const [couponSwitch, setCouponSwitch] = useState(false)
  const [couponProcess, setCouponProcess] = useState({ is_adding: false, is_complete: false, fail: false })
  const [cuponCode, setCouponCode] = useState(null)

  // get data from store 
  const pointData = useSelector((state) => state.filePointReducer)
  const userAdded = useSelector((state) => state.requireAvalibilityReducer)

  // cupon submit 
  const coupon_submit = async () => {
    console.log(userAdded)

    setCouponProcess((prev) => ({ ...prev, is_adding: true }))

    // return 
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const address = await signer.getAddress()
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + `add-cupon`, {
        method: "POST",
        body: JSON.stringify({
          "DID": userDid,
          "CouponCode": cuponCode,
          "Owner": address
        })
      })

      console.log("this is coupon code1:", cuponCode)


      if (res.status === 200) {
        if (userAdded.noUser) {
          await addUser();
        }
        setCouponProcess((prev) => ({ ...prev, is_adding: true, is_complete: true }))

        setTimeout(() => {
          setCouponProcess((prev) => ({ ...prev, is_adding: false, is_complete: false }))

          window.location.reload()
          setToggleToken(false)

        }, 3000);




      }
      else {
        setCouponProcess((prev) => ({ ...prev, is_adding: true, is_complete: false, fail: true }))

        setTimeout(() => {
          setCouponProcess((prev) => ({ ...prev, is_adding: false, is_complete: false, fail: false }))
          setToggleToken(false)
        }, 3000);
      }

    } catch (error) {
      setCouponProcess((prev) => ({ ...prev, is_adding: true, is_complete: false, fail: true }))

      setTimeout(() => {
        setCouponProcess((prev) => ({ ...prev, is_adding: false, is_complete: false, fail: false }))
        setToggleToken(false)
      }, 3000);
    }

  }

  const handleCouponCodeChange = (code) => {
    setCouponCode(code); // Update parent's state
  };

  const handlepaseCoupon = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");

    // Here we'll need to simulate a change event for the input manually
    const updatedValue = pastedText;
    setCouponCode(updatedValue);
  }

  // connect metamask user fn
  const metamaskConnect = async () => {
    try {
      if (!window.ethereum) {
        return;
      }

      var accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
        params: [account, "latest"],
      });
      var chainId = window.ethereum.networkVersion;

      if (chainId !== ChainId) {
        setPolygonMainnet(true)
      }
    } catch (e) {
      console.log(e);
    }
  };

  // check did validation if not route to login page
  const checkUserDidAvailable = () => {
    userDid && userDid.did === "" ? Navigate("/") : console.log("")
  }

  const [addingUserStats, setAddingUserStats] = useState({
    s1: false, s2: false, s3: false, msg: '', addComplete: false, verifyComplete: false,
    planComplete: false, planFail: false, alldone: false
  })

  // hide add user container 
  const [toggleNoUser, setToggleNoUser] = useState(true)

  // trim the did here
  const toggle = () => {
    dispatch(toggleSidebarSlice.actions.toggleSidebar());
  };

  // for bethel Beckz wallet 
  const [sendSec, setSendSec] = useState(true)
  const [reciveSec, setReciveSec] = useState(false)
  const [copiedStyle, setCopiedStyle] = useState("")
  const [showWallet, setShowWallet] = useState(false)

  // active send sec
  const activeSendSec = () => {
    setReciveSec(false)
    setSendSec(true)
  }

  // active revice Sec 
  const activeRecive = () => {
    setReciveSec(true)
    setSendSec(false)
  }

  // copy wallet address
  const copyWalletAddress = () => {
    navigator.clipboard.writeText(account.address)
    setCopiedStyle('bg-green-500')

    setTimeout(() => {
      setCopiedStyle('')
    }, 500);
  }

  // show and hide wallet
  const openWallet = () => {
    setShowWallet(!showWallet)
  }

  // becx price states 
  const [becx, setBecx] = useState(null)

  const becxState = useSelector((state) => state.becxSliceReducer) //get becx price from state

  // get market becx coint price
  const getBECXcoinPrize = async () => {

    if (becxState.price === '') {
      try {
        const res = await fetch(process.env.REACT_APP_BACKEND_URL + "becx")
        const becx = await res.json()

        if (res.status === 200) {
          const becxPrice = becx.data.BECX.quote.USD.price;
          dispatch(becxSlice.actions.saveBECXPrice({ price: becxPrice }))
          setBecx(becxPrice);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      setBecx(becxState.price)
    }

  }

  // get did connected wallet address 
  const get_connected_wallet_addresses = async () => {
    try {
      // if (!window.ethereum) {
      //   dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: true, useruserAdded: false, noUser: false, allreadyRegisteredWallet: false }))
      //   return
      // }

      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const singer = provider.getSigner()
      const contract = new ethers.Contract(fileStorageAddress, fileStorageABI, singer)
      const wallet_address = await singer.getAddress()

      // const res3 = await contract.getAllBatches()
      // console.log("dsaffdsf",res3)

      const res = await contract.getAdressList(userDid)
      setRegisterWalletAddress(res)

      const isUserHere = res.includes(wallet_address)

      if (res.length === 0) {
        dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, userAdded: false, noUser: true, allreadyRegisteredWallet: false }))
        return
      }

      if (isUserHere === false) {
        dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, userAdded: true, noUser: false, allreadyRegisteredWallet: false }))
        return
      }
      const ress = await contract.getUserDid()

      dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, userAdded: false, noUser: false, allreadyRegisteredWallet: false }))
    } catch (error) {
      console.log(error)
    }

  }

  // const navigate plan
  const navigatePlan = () => {
    dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, userAdded: false, noUser: true, allreadyRegisteredWallet: false }))
    setToggleNoUser(false)
  }

  // user adding plan funtion
  const addUser = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const contract = new ethers.Contract(fileStorageAddress, fileStorageABI, signer)

    // check if user added into this wallet addres
    const UserAddedRes = await contract.getUserDid()

    if (UserAddedRes === true) {
      dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, userAdded: false, noUser: false, allreadyRegisteredWallet: true }))
      return
    }

    setAddingUserStats((prev) => ({ ...prev, s1: true }))

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const address = signer.getAddress()

      const acc = await address


      const secret = ethers.BigNumber.from(
        ethers.utils.randomBytes(32)
      ).toString();
      const nullifier = ethers.BigNumber.from(
        ethers.utils.randomBytes(32)
      ).toString();

      const input = {
        secret: $u.BN256ToBin(secret).split(""),
        nullifier: $u.BN256ToBin(nullifier).split(""),
      };
      const jsonContent = JSON.stringify(input, null, 4);
      var res = await fetch("/addUser.wasm");
      var buffer = await res.arrayBuffer();
      var adduserWC = await wc(buffer);

      const r = await adduserWC.calculateWitness(input, 0);

      const commitment = r[1];
      const nullifierHash = r[2];
      const value = ethers.BigNumber.from("100000000000000000").toHexString();


      const resAddUser = await fetch(process.env.REACT_APP_BACKEND_URL + "add-user", {
        method: "POST",
        body: JSON.stringify({
          "Commitment": commitment.toString(),
          "Did": userDid,
          "NullifierHash": nullifierHash.toString(),
          "Owner": acc
        })
      })

      if (resAddUser.status === 200) {
        const add_user_data = await resAddUser.json()

        try {
          const proofElements = {
            nullifierHash: `${nullifierHash}`,
            secret: secret,
            nullifier: nullifier,
            commitment: `${commitment}`,
            txHash: add_user_data.TXHash,
          };
          const proof_element = btoa(JSON.stringify(proofElements));

          provider
            .waitForTransaction(add_user_data.TXHash)
            .then((_receipt) => {
              if (_receipt.status === 0) {
                console.log("Transaction failed.");

              } else {
                console.log("Transaction successful.");
                setAddingUserStats((prev) => ({ ...prev, s1: true, addComplete: true }))
              }
            })
            .catch((error) => {
              console.log("Errorss:", error);
            })

          // run verification process function
          await verifyUser(proof_element, acc, provider)



        } catch (e) {
          console.log(e);
          setAddingUserStats((prev) => ({ ...prev, s1: false, addComplete: false, verifyComplete: false }))
        }
      } else {
        setAddingUserStats((prev) => ({ ...prev, s1: false, addComplete: false, verifyComplete: false }))
      }
    } catch (error) {
      console.log(error)
      setAddingUserStats((prev) => ({ ...prev, s1: false, addComplete: false, verifyComplete: false }))
    }


  };

  //verify user 
  const verifyUser = async (proof_element, acc, provider, claim) => {

    try {
      const proofElements = JSON.parse(atob(proof_element));

      const receipt = await window.ethereum.request({
        method: "eth_getTransactionReceipt",
        params: [proofElements.txHash],
      });
      if (!receipt) {
        throw "empty-receipt";
      }


      const log = receipt.logs[0];

      const decodedData = await fileStorageInterface.decodeEventLog(
        "UserCollected",
        log.data,
        log.topics
      );

      const SnarkJS = window["snarkjs"];

      const proofInput = {
        root: $u.BNToDecimal(decodedData.useroot),
        nullifierHash: proofElements.nullifierHash,
        recipient: $u.BNToDecimal(acc),
        secret: $u.BN256ToBin(proofElements.secret).split(""),
        nullifier: $u.BN256ToBin(proofElements.nullifier).split(""),
        hashPairings: decodedData.userHashPairings.map((n) =>
          $u.BNToDecimal(n)
        ),
        hashDirections: decodedData.userPairDirection,
      };

      const { proof, publicSignals } = await snarkjs.groth16.fullProve(
        proofInput,
        "/verifyUser.wasm",
        "/setup_final.zkey"
      );

      const providers = new ethers.providers.Web3Provider(window.ethereum)
      const accounts = await providers.send('eth_requestAccounts', []);

      const callInputs = [
        proof.pi_a.slice(0, 2).map($u.BN256ToHex),
        proof.pi_b
          .slice(0, 2)
          .map((row) => $u.reverseCoordinate(row.map($u.BN256ToHex))),
        proof.pi_c.slice(0, 2).map($u.BN256ToHex),
        publicSignals.slice(0, 2).map($u.BN256ToHex),
        userDid
      ];

      const resUserVerify = await fetch(process.env.REACT_APP_BACKEND_URL + "verify-user", {
        method: "POST",
        body: JSON.stringify(
          {
            "A": JSON.stringify(proof.pi_a.slice(0, 2).map($u.BN256ToHex)),
            "B": JSON.stringify(proof.pi_b
              .slice(0, 2)
              .map((row) => $u.reverseCoordinate(row.map($u.BN256ToHex)))),
            "C": JSON.stringify(proof.pi_c.slice(0, 2).map($u.BN256ToHex)),
            "Inputs": JSON.stringify(publicSignals.slice(0, 2).map($u.BN256ToHex)),
            "Did": userDid,
            "Owner": accounts[0]
          }
        )
      })
      if (resUserVerify.status === 200) {
        const tx_hash = await resUserVerify.json()
        provider
          .waitForTransaction(tx_hash.TXHash)
          .then((_receipt) => {
            if (_receipt.status === 0) {
              console.log("Transaction failed.");
              setAddingUserStats((prev) => ({ ...prev, s1: false, addComplete: false, verifyComplete: false }))
            } else {
              console.log("Transaction successful.");
              setAddingUserStats((prev) => ({ ...prev, s1: true, addComplete: true, verifyComplete: true }))
              get_connected_wallet_addresses() // for refresh the state to original 

              setTimeout(() => {
                setAddingUserStats((prev) => ({ ...prev, s1: false }))
              }, 2000);
            }
          })
      }
      else {
        //if fails the actions
        setAddingUserStats((prev) => ({ ...prev, s1: false, addComplete: false, verifyComplete: false }))

      }
    } catch (error) {
      console.log(error) //if fails the actions
      setAddingUserStats((prev) => ({ ...prev, s1: false, addComplete: false, verifyComplete: false }))

    }
  };



  // check pakage is expire or not
  const checkPlanExpire = async () => {
    if (window.ethereum) {
      const provieder = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provieder.getSigner()
      const contract = new ethers.Contract(fileStorageAddress2, fileStorageABI2, signer)

      try {
        const packageSpace = await contract.checkPackageSpace(userDid)
        const planActivation = await contract.checkPackageActivate(userDid)
        const planExpire = await contract.checkPackageExpire(userDid)


        // dispatch the user status to redux
        dispatch(planStatusSlice.actions.saveStatus({
          planActive: planActivation,
          planExpire: planExpire,
          planSpace: parseInt(packageSpace)
        }))

      } catch (error) {
        console.log(error)
        dispatch(planStatusSlice.actions.saveStatus({
          planActive: false,
          planExpire: true,
          planSpace: null
        }))

      }

    }

  }

  // listen whether metamask account changes 
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        window.location.reload()
      })

      window.ethereum.on('chainChanged', (chainId) => {
        window.location.reload();
      });
    }

  }, [])

  useEffect(() => {
    dispatch(uploadStatusSlice.actions.updateUpload({
      isUploading: false,
      isCompletet: false,
      barWidth: "w-[0%]"
    }))

    dispatch((dispatch, getState) => {
      dispatch(filePointsSlice.actions.savePoints({
        points: 0,
        is_added: false,
      }))
    })
  }, [])

  // upload status fns 
  const uploadStatus = useSelector((state) => state.uploadStatusReducer)

  // state upload complete trigger
  useEffect(() => {
    if (uploadStatus.isCompletet) {
      dispatch(uploadTriggerSlice.actions.saveTriger({ trigger: true }))
    }
  }, [uploadStatus, dispatch])

  // show metamask instruction guide
  const [toggleMetaMaskGuide, setToggleMetaMaskGuide] = useState(false)
  const totalPoints = useSelector((state) => state.totalPointReducer) //get total points from store

  // get total points 
  const getTotalPoints = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-counts", {
        method: "POST",
        body: JSON.stringify({
          "DID": userDid
        })
      })

      const totalPoints = await res.json()
      console.log(totalPoints)
      dispatch(totalPointsSlice.actions.saveTotalPoints({ totalPoints: totalPoints.Points }))

    } catch (error) {
      console.log(error)
    }
  }


  return (
    <section className="">
      <div className="fixed top-3 lg:pl-[270px] md:px-2 sm:px-2 min-[320px]:px-2 w-full px-2 z-100">


        {/* start-main nav bar */}
        <div
          className="relative flex backdrop-blur-xl bg-white/10`
         w-full h-[70px] rounded-md text-white items-center px-4 justify-between"
        >

          {/* left side - kyc verification button */}
          {/* <NavLink
            className={(isActive) => {
              return isActive.isActive
                ? "bg-bethel-green rounded-md font-bold"
                : "";
            }}
            to="/dashboard/KYC"
          >
            <div className="text-[.7rem] gap-1 w-[120px] border-[1px] border-grayscale flex justify-center px-3 py-2 rounded-md items-center">
              <div>
                {" "}
                <img src={iconVerification} className="w-[20px] grayscale " alt="" />
              </div>
              <div>KYC Verify </div>


            </div>
          </NavLink> */}

          {/* file upload redirect button */}
          <NavLink
            className={(isActive) => {
              return isActive.isActive
                ? "bg-bethel-green rounded-md font-bold animate-none text-bethel-white"
                : " text-bethel-green  font-bold border-bethel-green border-[1px] rounded-lg";
            }}
            to="/dashboard/files"
          >
            <div className="text-[.7rem] gap-1 w-[140px] flex justify-center px-3 py-2 rounded-md items-center">
              <div>
                <img src={iconUpload} className="w-[20px] " alt="" />
              </div>
              <div className="text-[14px] ">Upload Now</div>


            </div>


          </NavLink>



          {/* points div */}
          <div className="text-bethel-green w-[200px] px-3 py-3 flex items-center justify-center bg-bethel-green/10 rounded-lg mx-2
            ">
            <h3 className="font-semibold lg:text-[12px] md:text-[10px] sm:text-[10px] min-[320px]:text-[8px]">Total Points  <span className="py-1 px-1 bg-bethel-green/10 rounded-lg">{totalPoints && totalPoints.totalPoints}</span></h3>
          </div>

          {/* right side */}
          <div className="flex gap-2 text-[1rem] items-center justify-end w-full relative">

            {/* becx price api */}
            <div className="w-[150px] lg:scale-100 md:scale-100 bg-[#0F0F0F] sm:scale-100 absolute 
            right-2 lg:right-2 md:right-8 sm:right-8 min-[320px]:right-2 min-[320px]:scale-75 
            flex items-center gap-2 px-3 py-2 rounded-lg border-[1px] border-bethel-green/50 z-[100] ">
              <img src={bethelLogo} className="w-[25px]" alt="" />
              <div className="flex flex-col">
                <h3 className="text-[10px] text-white/50">Today BECX price:</h3>
                <h3 className="text-[12px]">${becx ? becx.toFixed(3) : "calculating"}</h3>
              </div>

            </div>

            {/* becx price api */}
            <div className="w-[150px] lg:scale-100 md:scale-100 bg-[#0F0F0F] sm:scale-100 absolute lg:flex md:hidden
            right-44 lg:right-44 md:right-48 sm:right-48 min-[320px]:right-2 min-[320px]:scale-75 
            flex items-center gap-2 px-3 py-3 rounded-lg border-[1px] border-bethel-green/50 z-[100] ">
              <div className="flex flex-col">
                <h3 className="text-[12px] text-white/50">Buy BECX</h3>
              </div>
              <a href="https://www.mexc.com/exchange/BECX_USDT" target="_blank" rel="noreferrer"><img src={mecx} className="w-[30px]" alt="" /></a>
              <a href="https://www.bitmart.com/trade/en-US?symbol=BECX_USDT" target="_blank" rel="noreferrer"><img src={bit} className="w-[18px]" alt="" /></a>


            </div>


            <a href="https://metamask.app.link/dapp/https://testnet.bethel.network/" className="w-[150px] lg:scale-100 md:scale-[.7] bg-[#0F0F0F] sm:scale-100 absolute lg:hidden md:flex
            right-44 lg:right-44 md:right-44 sm:right-48 min-[320px]:right-2 min-[320px]:scale-75 
            flex items-center gap-2 px-3 py-3 rounded-lg border-[1px] border-bethel-green/50 z-[100] "><button className="">
                Connect Wallet
              </button></a>


            {/* cuppon code div */}
            <div className="w-full items-center ml-2 hidden">
              <div className="flex">
                {/* input fiedl */}
                {couponSwitch &&
                  <div>
                    <input onChange={(e) => setCouponCode(e.target.value)} onPaste={handlepaseCoupon} type="text" className="w-[200px] outline-none bg-bethel-green/10 border border-bethel-newgreen  rounded-lg  text-[12px] p-2 placeholder:text-white/70 placeholder:text-[12px] animate__animated animate__headShake" placeholder="coupon code" />
                  </div>
                }

                {/* button */}
                {couponSwitch ?
                  <button disabled={couponProcess.is_adding} onClick={coupon_submit} className={`text-bethel-newgreen ${couponProcess.is_adding ? "border border-bethel-newgreen" : ""} w-[90px] flex bg-bethel-green/10 justify-center px-3 py-2 rounded-lg  mx-2 animate__animated animate__headShake`}>
                    {!couponProcess.is_adding && <h3 className="font-semibold animate-pulse text-[12px]">Sumbit</h3>}

                    {/* loading screen */}
                    {couponProcess.is_adding && !couponProcess.is_complete && !couponProcess.fail &&
                      <div className="flex items-center gap-1">
                        <img src={loadingGif} className="w-[15px]" alt="" />
                        <h3 className="animate-pulse text-[10px] text-bethel-newgreen font-semibold animate__animated animate__bounceIn">Loading</h3>
                      </div>
                    }

                    {/* sucess */}
                    {couponProcess.is_adding && couponProcess.is_complete &&
                      <div className="flex items-center gap-1">
                        <img src={iconDone} className="w-[15px]" alt="" />
                        <h3 className="animate-pulse text-[10px] text-bethel-newgreen font-semibold animate__animated animate__bounceIn">Done</h3>
                      </div>
                    }

                    {/* fail */}
                    {couponProcess.is_adding && couponProcess.fail &&
                      <div className="flex items-center gap-1">
                        <img src={iconClose} className="w-[15px]" alt="" />
                        <h3 className="animate-pulse text-[10px] text-red-600 font-semibold animate__animated animate__bounceIn">Already Added.</h3>
                      </div>
                    }

                  </button>
                  :

                  <button onClick={() => setToggleToken(true)} className="text-bethel-newgreen w-fit px-3 py-2 bg-bethel-green/10 rounded-lg animate__animated animate__headShake">
                    <h3 className="font-semibold animate-pulse text-[12px]">Apply Coupon</h3>
                  </button>
                }

                {
                  couponProcess.is_complete &&
                  <div className="p-1 bg-green-500 flex items-center px-2 relative ml-2 animate__animated animate__fadeIn">
                    <div className="absolute left-[-6px] w-0 h-0 border-t-[8px] border-t-transparent border-b-[8px] border-b-transparent border-r-[8px] border-r-green-500"></div>
                    <h3 className="text-[10px]">Coupon added sucessfully</h3>
                  </div>
                }

                {
                  couponProcess.fail &&
                  <div className="p-1 bg-red-500 flex items-center px-2 relative ml-2 animate__animated animate__fadeIn">
                    <div className="absolute left-[-6px] w-0 h-0 border-t-[8px] border-t-transparent border-b-[8px] border-b-transparent border-r-[8px] border-r-red-500"></div>
                    <h3 className="text-[10px]">Coupon added Failed</h3>
                  </div>
                }

              </div>

            </div>


            <div className="w-[150px]  h-[50px] lg:scale-100 md:scale-100 sm:scale-100 absolute 
            right-2 lg:right-2 md:right-8 sm:right-8 min-[320px]:right-2 min-[320px]:scale-75 
            flex items-center gap-2 px-3 py-2 rounded-lg shadow-[0_0_20px_rgba(0,255,0,0.5)] animate-pulse"></div>

            <div className="navbar-right flex gap-2 hidden">
              {/* user bethle wallet */}
              <div className="relative lg:mr-[170px] md:mr-[190px] sm:mr-[190px] min-[320px]:mr-[140px]
              min-[320px]:scale-[0.8] lg:scale-100 md:scale-100
              flex items-center justify-center bg-[#0F0F0F]">
                <button onClick={openWallet}
                  className={`p-2 border-[2px] ${showWallet ? "bg-bethel-green" : ''} border-white rounded-full`}>
                  <img src={walletIcon} alt="" className="w-[25px]" />
                </button>


                {/* wallet div */}
                {showWallet &&
                  <div className={`animate__animated animate__faster ${showWallet ? "animate__fadeInRight" : "animate__fadeOutRight"}
                   absolute top-14 
                   lg:right-0 md:right-0 sm:right-0 min-[320px]:right-[-40px] w-[320px] 
                   h-[500px] bg-black bg-inherit shadow-lg shadow-bethel-green/10 border-bethel-green border-[1px] z-[9000] rounded-lg p-3`}>
                    {/* bethel logo and discription div */}
                    <div className="flex justify-start gap-2 p-2 bg-white/20 rounded-xl w-[200px]">
                      <img src={bethelLogo} alt="" className="flex items-center justify-start w-[20px]" />
                      <h1 className="font-normal text-[14px]">Bethel Wallet</h1>
                    </div>

                    {/* wallet address */}
                    <div className="px-2 py-2 flex text-white/40 items-center">
                      <h3 className="text-[10px]">Wallet Address : </h3>
                      <h3 className="text-[10px] ml-2">
                        {account &&
                          account.address.slice(0, 18) +
                          "..." + account.address.slice(35)}
                      </h3>
                      <button onClick={copyWalletAddress} className={`relative p-1 rounded-full ${copiedStyle ? "bg-green-500" : ""} flex items-center justify-center ml-2`}>
                        <img src={copyIcon} alt="" className="w-[15px] opacity-40" />
                      </button>
                    </div>

                    {/* balance and send tracsaction */}
                    <div className="flex flex-col p-4 rounded-lg mt-1 gap-3 bg-gradient-to-r from-bethel-green/50 to-bethel-newgreen/50">
                      <h3 className="font-normal text-[12px] text-white/60 tracking-wide">Total Balance</h3>
                      <h1 className="text-[30px] text-white font-bold">{account ?
                        ((account.balance * 10).toFixed(2) + " MATIC") : "0 MATIC"
                      }
                        <span className="flex text-[15px]">{account ?
                          "($" + ((account.balance * 10).toFixed(2)) * 0.55 + ")" : "$0"}
                        </span></h1>

                    </div>

                    {/* send and recive icon list */}
                    <div className="w-full flex gap-2 justify-start mt-3">
                      {/* send icon */}
                      <button onClick={activeSendSec}
                        className={`flex flex-col items-center justify-center ${sendSec ? "bg-white/20" : ''} px-2 py-1  rounded-t-lg`}>
                        <h3 className="text-[13px]">Send</h3>
                      </button>



                      {/* recive icon */}
                      <button onClick={activeRecive}
                        className={`flex flex-col items-center justify-center ${reciveSec ? "bg-white/20" : ''} px-2 py-1  rounded-t-lg`}>
                        <h3 className="text-[13px]">Recive</h3>
                      </button>
                    </div>

                    {/* input fields for send tokens */}
                    {sendSec && <div className="flex flex-col pt-2 pb-4 px-2 mt-0 bg-white/20 rounded-r-lg rounded-bl-lg">
                      <form action="">
                        {/* send address */}
                        <label htmlFor="" className="text-white/50 text-[12px] ">
                          Send Tokens:
                        </label>

                        <div className="flex relative">
                          <input type="text" className="flex border-[1px] text-[12px] border-white/20 bg-transparent w-[85%] rounded-lg mt-2 p-2 outline-none" placeholder="Enter Wallet Address" />

                          <button className="absolute right-1 p-2 rounded-full border-[1px] flex flex-col top-3">
                            <img src={arrowUp} alt="" className="w-[10px]" />
                          </button>
                        </div>

                      </form>
                    </div>}

                    {/* input fields for Recive tokens */}
                    {reciveSec && <div className="flex flex-col pt-2 pb-4 px-2 mt-0 bg-white/20 rounded-lg">
                      <form action="">
                        {/* send address */}
                        <label htmlFor="" className="text-white/50 text-[12px]">
                          Recive Tokens:
                        </label>

                        <div className="flex relative">
                          <input type="text" className="flex border-[1px] text-[12px] border-white/20 bg-transparent w-[85%] rounded-lg mt-2 p-2 outline-none" placeholder="Enter Wallet Address" />
                          <button className="absolute right-1 p-2 rounded-full border-[1px] flex flex-col top-3">
                            <img src={arrowDown} alt="" className="w-[10px]" />
                          </button>
                        </div>
                      </form>
                    </div>}


                  </div>
                }
                {/* end of wallet div */}

              </div>
              {/* end of user bethel wallet */}



            </div>
          </div>

          {/* toggle sidebar  */}
          <button onClick={toggle} className="absolute right-3 lg:hidden md:flex sm:flex min-[32px]:flex">
            <img src={iconMenu} alt="" className="w-[25px]" />
          </button>
        </div>
        {/* end-nav bar */}
      </div>



      {/* meta mask install msg */}
      {avalibilityData.metamask && <div className='w-full'>
        <a href="https://metamask.io/download/" target='_blank' rel="noreferrer">
          <MetamaskInstall installMetaMask={"Installed"} header={"Oops! MetaMask is not detected!"} img={metaMaskLogo} li1={"MetaMask extension is not detected on your browser. Please install MetaMask to proceed!"} li2={"Click this box to install MetaMask manually."} /></a>


        <div className='fixed -right-14 top-[270px] -rotate-12 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
          <img src={robot} className='w-[150px] opacity-80 ' alt="" />
        </div>

      </div>}

      {avalibilityData.userAdded && !polygonMainnnet && <div className={`${addingUserStats.s1 ? "hidden" : ""} w-full`}>
        <MetamaskInstall header={"Oops! DID is not match with wallet address !"} img={validationIcon} addUser={addUser} userAdding={addingUserStats.s1}
          li1={"Your DID did not match with wallet address."} li2={"Here is list of address that connected with this DID"} li3={"Please connect one of them or Click Add New User to add new user to this wallet address"}
          walletList={registerWalletAddress && registerWalletAddress} />

        {/* div ai robot div */}
        <div className='fixed -right-14 top-[350px] -rotate-12 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
          <img src={robot} className='w-[150px] opacity-80 ' alt="" />
        </div>

      </div>}

      {avalibilityData.allreadyRegisteredWallet && <div className='w-full'>
        <MetamaskInstall header={"Oops! DID is not match with wallet address !"} img={validationIcon}
          li1={"Wallet address already registered!"} li2={"This wallet address that your are using is already register with another DID"} li3={"Please use another Wallet address for login. "}
        />

        {/* div ai robot div */}
        <div className='fixed -right-14 top-[350px] -rotate-12 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
          <img src={robot} className='w-[150px] opacity-80 ' alt="" />
        </div>

      </div>}


      {((avalibilityData.noUser && toggleNoUser) && !toggleToken) &&
        <Link to="/dashboard/billing" className='w-full'>
          <button className="w-full text-left" onClick={navigatePlan}>
            <MetamaskInstall header={"Oops! Theres is no User for this DID !"} img={validationIcon}
              li1={"Your DID did not match with any wallet address."} li2={"You are eligible to select the BASIC PLAN or enter COUPON CODE to Proceed!"} li3={"Click here to ACTIVATE plans"} li3_1={" *Please COMPLETE account verification for more plans"}
            />
          </button>


          {/* div ai robot div */}
          <div className='fixed -right-14 top-[300px] -rotate-12 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
            <img src={robot} className='w-[150px] opacity-80 ' alt="" />
          </div>

        </Link>}

      {addingUserStats.s1 && <div>
        <div className="fixed z-[99999] right-8 bottom-[210px] w-[400px] inline-block text-sm text-white border border-gray-200 rounded-lg shadow-sm bg-green-400 animate__animated animate__fadeInRight animate__fast">
          {/* Header with MetaMask Logo */}
          <div className="px-3 py-2 bg-green-600 border-b border-gray-200 rounded-t-lg flex gap-2 items-center">
            <div className="p-2 rounded-full bg-white">
              <img src={verifyIcon} className="w-[30px]" alt="" />
            </div>
            <h3 className="font-semibold text-white text-[16px]">User Verification in progress !</h3>
          </div>

          {/* Content */}
          <div className="px-3 py-4 flex flex-col">
            <h4 className='font-bold text-black mb-3'>User adding and verifying in progress. Please do not close this window nor refresh page ! </h4>
            <ul className="list-disc pl-5 text-black/70 font-bold text-[14px] flex flex-col gap-1">
              <div className='flex items-center gap-2'>
                <li>User adding ...</li>
                {!addingUserStats.addComplete && <img src={loadingGif} className='w-[25px]' alt="" />}
                {addingUserStats.addComplete && <img src={iconDone} className='w-[25px]' alt="" />}
              </div>
              <div className='flex items-center gap-2'>
                <li>User Verifying...</li>
                {!addingUserStats.verifyComplete ? <img src={loadingGif} className='w-[25px]' alt="" /> :
                  <img src={iconDone} className='w-[25px]' alt="" />}
              </div>
            </ul>
          </div>

          {/* Triangle Pointer */}
          <div className="absolute bottom-0 right-20 transform translate-y-full w-6 h-6 border-t-[30px] border-t-green-400 border-l-[30px] border-l-transparent"></div>
        </div>

        {/* div ai robot div */}
        <div className='z-[1000] fixed -bottom-10 -right-12 -rotate-6 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
          <img src={robot} className='w-[150px] opacity-100 ' alt="" />
        </div>
      </div>}

      {/* upload status div */}
      {uploadStatus.isUploading &&
        <div className="bg-black w-[300px] p-5 fixed bottom-4 right-3 justify-center items-center flex border-[1px] border-bethel-green rounded-lg animate-pulse">
          <div className="flex items-center text-white relative">
            <h3 className="flex text-white absolute -top-11 -left-10 font-bold text-[12px] ">{uploadStatus.msg}</h3>
            <img src={uploadGif} className="w-[60px] absolute -left-[55px]" alt="" />

            <div className="flex flex-col gap-1">
              <div class="w-[200px] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div className={`bg-green-500 h-2.5 rounded-full ${uploadStatus.barWidth} transition-all ease-out duration-1000 animate__animated animate__pulse animate__infinite	infinite animate__slow`}></div>
              </div>
            </div>

          </div>
        </div>}

      {/* upload complete div */}
      {uploadStatus.isCompletet &&
        <div className="bg-black w-[300px] p-4 fixed bottom-4 right-3 justify-center items-center flex border-[1px] border-bethel-green rounded-lg ">
          <div className="flex items-center text-white relative">
            <img src={iconDone} className="w-[25px] absolute -left-[35px] animate__animared animate__bounceIn" alt="" />

            <div className="flex flex-col gap-1 text-green-600 font-bold text-[15px]">
              Upload Success!
            </div>

          </div>
        </div>}

      {uploadStatus.isFail &&
        <div className="bg-black w-[300px] p-4 fixed bottom-4 right-3 justify-center items-center flex border-[1px] border-red-500 rounded-lg ">
          <div className="flex items-center text-white relative">
            <img src={iconClose} className="w-[25px] absolute -left-[35px] animate__animared animate__bounceIn" alt="" />

            <div className="flex flex-col gap-1 text-red-600 font-bold text-[15px]">
              Upload Fail!
            </div>

          </div>
        </div>}


      {/* meta mask install msg */}
      {polygonMainnnet &&
        <div className='w-full'>
          <MetamaskInstall installMetaMask={"AddMainnet"} header={"Invalid Network Detected!"} img={metaMaskLogo} li1={"System deceted that you are not in Polygon Main net currently"} li2={"Please change it to POLYGON MAIN NET or click ADD to add Mainnet into your Metamask wallet "} />

          {/* div ai robot div */}
          <div className='fixed -right-14 top-[270px] -rotate-12 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
            <img src={robot} className='w-[150px] opacity-80 ' alt="" />
          </div>

        </div>}

      {/* meta mask installtion guide */}
      {avalibilityData.metamask && toggleMetaMaskGuide && <MetamaskInstalationGuide colseBtn={() => setToggleMetaMaskGuide(false)} />}

      {/* guide div */}
      {!toggleMetaMaskGuide && avalibilityData.metamask && <button onClick={() => setToggleMetaMaskGuide(true)} className="fixed bg-black border border-white/30 bottom-0 right-0 flex justify-center items-center gap-2 p-2 rounded-lg animate__animated animate__bounce animate__infinite	infinite animate__slower">
        <img src={metaMaskLogo} className="w-[20px]" alt="" />
        <h3 className="text-orange-500 text-[12px]">Meta Mask Installation Guide</h3>
      </button>
      }

      {/* coupon code designs  */}
      {toggleToken && <Token2049Coupon addCoupon={coupon_submit} onCouponCodeChange={handleCouponCodeChange} couponProcess={couponProcess} closeBtn={() => setToggleToken(false)} />}

      {/* file point added div */}
      {pointData.is_added && <div className="fixed bottom-3 right-2 bg-green-700  py-2 px-4 text-white font-bold text-[0.8rem] tracking-wider animate__animated aniamte__fadeIn">
        <h3>You've earned <span className="text-bethel-newgreen">{pointData && pointData.points} </span>points</h3>
      </div>}


    </section>
  );
}

export default Navbar;
