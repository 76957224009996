import React, { useEffect } from 'react'
import iconEdit from "../../Images/icons/icon-edit.png"
import { useSelector } from 'react-redux'

function ProfileFormCard({ label_first_name, input_first_name, lable_last_name, input_last_name, label_city, input_city, label_mobile_number, input_mobile_number, label_addressLine1, label_addressLine2, input_addressLine1, input_addressLine2,
    input_country, input_email, input_state, input_zip_code, onBtnClick, blur
}) {
    
    return (
        <div className={`flex lg:w-full md:w-full sm:w-full min-[320px]:w-full h-auto bg-black border-bethel-green/30 border-[1px] rounded-lg mt-2 px-4 py-6 relative ${blur ? 'blur-sm' : ''}`}>
            <div action="" className='flex justify-around w-full gap-4 flex-wrap mt-2'>

                <div className='flex flex-col gap-1 w-full lg:w-[370px] md:w-[300px] sm:w-full min-[320px]:w-full'>
                    <label className='flex text-[14px] text-white/80' htmlFor="">{label_first_name} :</label>
                    <input disabled type="text" placeholder={input_first_name} className='placeholder-bethel-green/50 border-bethel-green/30 flex text-[12px] bg-transparent py-2 px-4 border-b-[1px] rounded-lg' />
                </div>

                <div className='flex flex-col gap-1 w-full lg:w-[370px] md:w-[300px] sm:w-full min-[320px]:w-full'>
                    <label className='flex text-[14px] text-white/80' htmlFor="">{lable_last_name} :</label>
                    <input disabled type="text" placeholder={input_last_name} className='placeholder-bethel-green/50 border-bethel-green/30 flex text-[12px] bg-transparent py-2 px-4 border-b-[1px] rounded-lg' />
                </div>

                <div className='flex flex-col gap-1 w-full lg:w-[370px] md:w-[300px] sm:w-full min-[320px]:w-full'>
                    <label className='flex text-[14px] text-white/80' htmlFor="">Email :</label>
                    <input disabled type="text" placeholder={input_email} className='placeholder-bethel-green/50 border-bethel-green/30 flex text-[12px] bg-transparent py-2 px-4 border-b-[1px] rounded-lg' />
                </div>

                <div className='flex flex-col gap-1 w-full lg:w-[370px] md:w-[300px] sm:w-full min-[320px]:w-full'>
                    <label className='flex text-[14px] text-white/80' htmlFor="">{label_addressLine1} :</label>
                    <input disabled type="text" placeholder={input_addressLine1} className='placeholder-bethel-green/50 border-bethel-green/30 flex text-[12px] bg-transparent py-2 px-4 border-b-[1px] rounded-lg' />
                </div>

                <div className='flex flex-col gap-1 w-full lg:w-[370px] md:w-[300px] sm:w-full min-[320px]:w-full'>
                    <label className='flex text-[14px] text-white/80' htmlFor="">{label_addressLine2} :</label>
                    <input disabled type="text" placeholder={input_addressLine2} className='placeholder-bethel-green/50 border-bethel-green/30 flex text-[12px] bg-transparent py-2 px-4 border-b-[1px] rounded-lg' />
                </div>

                <div className='flex flex-col gap-1 w-full lg:w-[370px] md:w-[300px] sm:w-full min-[320px]:w-full'>
                    <label className='flex text-[14px] text-white/80' htmlFor="">{label_city} :</label>
                    <input disabled type="text" placeholder={input_city} className='placeholder-bethel-green/50 border-bethel-green/30 flex text-[12px] bg-transparent py-2 px-4 border-b-[1px] rounded-lg' />
                </div>

                <div className='flex flex-col gap-1 w-full lg:w-[370px] md:w-[300px] sm:w-full min-[320px]:w-full'>
                    <label className='flex text-[14px] text-white/80' htmlFor="">{label_mobile_number} :</label>
                    <input disabled type="text" placeholder={input_mobile_number} className='placeholder-bethel-green/50 border-bethel-green/30 flex text-[12px] bg-transparent py-2 px-4 border-b-[1px] rounded-lg' />
                </div>

                <div className='flex flex-col gap-1 w-full lg:w-[370px] md:w-[300px] sm:w-full min-[320px]:w-full'>
                    <label className='flex text-[14px] text-white/80' htmlFor="">State :</label>
                    <input disabled type="text" placeholder={input_state} className='placeholder-bethel-green/50 border-bethel-green/30 flex text-[12px] bg-transparent py-2 px-4 border-b-[1px] rounded-lg' />
                </div>

                <div className='flex flex-col gap-1 w-full lg:w-[370px] md:w-[300px] sm:w-full min-[320px]:w-full'>
                    <label className='flex text-[14px] text-white/80' htmlFor="">Zip Code :</label>
                    <input disabled type="text" placeholder={input_zip_code} className='placeholder-bethel-green/50 border-bethel-green/30 flex text-[12px] bg-transparent py-2 px-4 border-b-[1px] rounded-lg' />
                </div>

                <div className='flex flex-col gap-1 w-full lg:w-[370px] md:w-[300px] sm:w-full min-[320px]:w-full'>
                    <label className='flex text-[14px] text-white/80' htmlFor="">Country :</label>
                    <input disabled type="text" placeholder={input_country} className='placeholder-bethel-green/50 border-bethel-green/30 flex text-[12px] bg-transparent py-2 px-4 border-b-[1px] rounded-lg' />
                </div>
            </div>


            {/* edit icon */}
            <button onClick={onBtnClick} className='flex hover:bg-bethel-green/20 text-white absolute top-2 right-2 gap-1 items-center bg-white/10 p-2 rounded-lg'>
                <img src={iconEdit} className='w-[15px] h-[15px] opacity-60' alt="" />
                <h3 className='text-white/50 text-[12px]'>Edit</h3>
            </button>
        </div>
    )
}

export default ProfileFormCard