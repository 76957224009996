import React, { useEffect, useRef, useState } from "react";
import iconStorage from "../Images/icons/icon-storage.png";
import FileSharedComp from "../components/FIles-ShareFiles";
import TableWithMoreButton from "../components/Test";
import "../loadingCss/InfiniteLoader.css";
import iconWrong from "../Images/icons/icons-close.png";
import { useDispatch, useSelector } from "react-redux";
import robot from "../Images/BG-Images/robot.png"
import iconExpired from "../Images/icons/icon-expired.png"
import { NavLink, useNavigate } from "react-router-dom";
import uploadStatusSlice from "../reducers/UploadStatusSlice";
import { ethers } from "ethers";
import filePointsSlice from "../reducers/filePointsReducer";
import totalPointsSlice from "../reducers/totalPointsReducer";


export default function DashboardStorageFolder2() {
  const childRef = useRef();
  const childRef1 = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const account = useSelector((state) => state.currentAccountReducer);
  const approveContractAccess = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS_APPROVE;


  const fileStorageAddress = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS_2; // contract address
  const fileStorageJSON = require("../json/FileStorage2.json");
  const approveJSON = require("../json/ApproveSm.json");


  const approveJSONABI = approveJSON.abi;
  const fileStorageABI = fileStorageJSON.abi;


  const fileStorageJSON2 = require("../json/FileStorage2.json");
  const fileStorageAddress2 = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS_2
  const fileStorageABI2 = fileStorageJSON2.abi

  const fileStorageInterface = new ethers.utils.Interface(fileStorageABI);

  // get user did
  const did = useSelector((state) => state.DidReducer)

  // on chain data
  // const [onChainData , setOnChainData] = useState(null)
  const [fullFileSize, setFullFileSize] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFail, setUploadFail] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [uploadWait, setUploadWait] = useState(false);
  const [dashboardDetails, setDashboardDetails] = useState(null);
  const [changeUploadMethod, setChangeUploadMethod] = useState(true);
  const [singleOrBatch, setSingleOrBatch] = useState(false);
  const [filesCount, setFileCount] = useState(null)

  // for the bulk upload
  const [bulkUploads, setBulkUploads] = useState(null);
  const [bulkUploadsLoop, setBulkUploadsLoop] = useState(null);
  const [uploadBtnShow, setUploadBtnShow] = useState(false);

  // store variables
  const Udid = useSelector((state) => state.DidReducer);
  const blur = useSelector((state) => state.fileBlurReducer);

  const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)

  // sse event current uploading file name 
  const [currentFileName, setCurrentFileName] = useState("")

  // limit upload 
  const [maxUploadLimit, setMaxUploadLimit] = useState(false)

  //  to check avalibility 
  const [availability, setAvailabily] = useState(false)

  // if user not install meta mask navigate to dashboard 
  useEffect(() => {
    if (!window.ethereum) {
      navigate("/dashboard")
    }
  }, [])


  // import function from the child component for update user dowload details - single upload
  const childRefFunc = () => {
    if (childRef.current && childRef.current.getAllFile) {
      childRef.current.getAllFile();
    }
  };

  // get files count and used space
  const getFilesCount = async () => {
    const p = new ethers.providers.Web3Provider(window.ethereum)
    const s = p.getSigner()
    const contract = new ethers.Contract(fileStorageAddress, fileStorageABI, s)

    const res = await contract.getUsedSpace(did)
    setFileCount(parseInt(res._hex))
  }

  // single file uplaod fn
  const upload = async () => {
    const formData = new FormData();
    formData.append("owner_id", Udid);
    formData.append("file", file);
    formData.append("file_name", file.name);
    formData.append("file_size", file.size);

    if (file) {
      setUploadWait(true);

      try {
        const res = await fetch(process.env.REACT_APP_BACKEND_URL + "upload", {
          method: "POST",
          body: formData,
        });

        if (res.status === 200) {
          setUploadSuccess(true);
          setUploadWait(false);

          childRefFunc();

          setTimeout(() => {
            setUploadSuccess(false);
          }, 2000);

          setFile(null);
          setFileName("");
          setFileSize("");

        } else {
          setUploadFail(true);
          setUploadWait(false);

          setTimeout(() => {
            setUploadFail(false);
          }, 2000);
        }
      } catch (error) {
        setUploadWait(false);
        setUploadFail(true);

        setTimeout(() => {
          setUploadFail(false);
        }, 2000);
      }
    }
  };

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setFileSize(e.target.files[0].size);
  };

  //clear select upload items
  const deleteUpload = () => {
    setFile(null);
    setFileName("");
    setFileSize("");
  };

  // handle bulk upload fn
  const handleBulkUpload = (event) => {
    setUploadSuccess(false);
    const uploadss = event.target.files;

    // check if file upload is single or batch
    if (uploadss.length === 1) {
      setSingleOrBatch(true);
    } else {
      setSingleOrBatch(false);
    }

    // set uploading limit 
    if (uploadss.length === 41) {
      setMaxUploadLimit(true)

      setTimeout(() => {
        setMaxUploadLimit(false)
      }, 3000);

      setBulkUploads(null);
      return;

    }
    setBulkUploadsLoop(Array.from(uploadss));
    setBulkUploads(uploadss);
    setUploadBtnShow(true);
  };

  // delete all bulk upload
  const deleteBulkuploads = () => {
    setBulkUploadsLoop(null);
    setBulkUploads(null);
    setUploadBtnShow(false);
  };

  // check the validity of file uploads
  const checkValidity = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const contract = new ethers.Contract(fileStorageAddress2, fileStorageABI2, signer)

      const res = await contract.checkPackageExpire(did)
      try {
        if (res === false) {
          setAvailabily(true)
        }
        else {
          setAvailabily(false)
        }
      } catch (error) {
        setAvailabily(false)
      }
    } catch (error) {
      console.log(error)
    }

  }



  useEffect(() => {
    checkValidity()
    getFilesCount()
    getTotalPoints() 
  }, [])

  // bulk uplaod fn
  const bulkUpload = async () => {
    if (!bulkUploads) {
      return;
    }
    setUploadBtnShow(false);
    setUploadWait(true);


    dispatch(uploadStatusSlice.actions.updateUpload({
      isUploading: true,
      isCompletet: false,
      isFail: false,
      barWidth: "w-[0%]",
      msg: "Start Uploading file(s)"
    }))

    //to get approve smart contract
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = signer.getAddress()

    let fullfileSizes = null;

    const acc = await address


    const fd = new FormData();
    fd.append("did", Udid);
    fd.append("owner", acc)
    bulkUploadsLoop.map((file) => {
      fd.append("fileData", file);
      fullfileSizes = fullfileSizes + file.size;
    });

    setFullFileSize(fullfileSizes);

    dispatch(uploadStatusSlice.actions.updateUpload({
      isUploading: true,
      isCompletet: false,
      isFail: false,
      barWidth: "w-[30%]",
      msg: "Start Uploading file(s)"
    }))

    try {
      const contract = async () => {

        const provider = new ethers.providers.Web3Provider(window.ethereum)

        const account = await provider.send("eth_requestAccounts", []);
        const address = account[0];

        dispatch(uploadStatusSlice.actions.updateUpload({
          isUploading: true,
          isCompletet: false,
          isFail: false,
          barWidth: "w-[60%]",
          msg: "Uploding to the Blockchain ..."
        }))




        try {
          const res = await fetch(process.env.REACT_APP_BACKEND_URL + "upload", {
            method: "POST",
            "content-type": "application/json",
            body: fd
          });

          if (res.status === 201) {
            const tx_hash = await res.json();

            dispatch(uploadStatusSlice.actions.updateUpload({
              isUploading: true,
              isCompletet: false,
              isFail: false,
              barWidth: "w-[80%]",
              msg: "Uploding to the Blockchain"
            }))

            console.log("this is tx data :", tx_hash.FileCount)

            // smart contract 
            provider
              .waitForTransaction(tx_hash.TXHash)
              .then((_receipt) => {
                if (_receipt.status === 0) {
                  console.log("Transaction failed.");
                  setUploadWait(false);
                  setCurrentFileName("")

                  dispatch(uploadStatusSlice.actions.updateUpload({
                    isUploading: false,
                    isCompletet: false,
                    isFail: true,
                    barWidth: "w-[0%]",
                    msg: ""
                  }))

                  setTimeout(() => {
                    dispatch(uploadStatusSlice.actions.updateUpload({
                      isUploading: false,
                      isCompletet: false,
                      isFail: false,
                      barWidth: "w-[0%]",
                      msg: ""
                    }))
                  }, 1000);
                } else {
                  console.log("Transaction successful.");

                  // final phrase of 
                  finalUpload(fullfileSizes, acc, tx_hash.FileCount)
                }
              })
              .catch((error) => {
                console.log("Error:", error);
                setUploadWait(false);
                setCurrentFileName("")
                setTimeout(() => {
                  setUploadFail(false);
                }, 3000);


                dispatch(uploadStatusSlice.actions.updateUpload({
                  isUploading: false,
                  isCompletet: false,
                  isFail: true,
                  barWidth: "w-[0%]"
                }))

                setTimeout(() => {
                  dispatch(uploadStatusSlice.actions.updateUpload({
                    isUploading: false,
                    isCompletet: false,
                    isFail: false,
                    barWidth: "w-[0%]"
                  }))
                }, 3000);
              });


            // connect to smart contract
            // chunkAdd(onChain);
            setBulkUploadsLoop(null);

            setTimeout(() => {
              setUploadSuccess(false);
            }, 3000);
          } else {
            setUploadWait(false);
            setUploadFail(true);
            setCurrentFileName("")

            setTimeout(() => {
              setUploadFail(false);
            }, 3000);

            dispatch(uploadStatusSlice.actions.updateUpload({
              isUploading: false,
              isCompletet: false,
              isFail: true,
              barWidth: "w-[0%]"
            }))

            setTimeout(() => {
              dispatch(uploadStatusSlice.actions.updateUpload({
                isUploading: false,
                isCompletet: false,
                isFail: false,
                barWidth: "w-[0%]"
              }))
            }, 3000);

          }

        } catch (error) {
          console.log(error)
          setUploadWait(false);
          setUploadFail(true);
          setCurrentFileName("")

          setTimeout(() => {
            setUploadFail(false);
          }, 3000);

          dispatch(uploadStatusSlice.actions.updateUpload({
            isUploading: false,
            isCompletet: false,
            isFail: true,
            barWidth: "w-[0%]"
          }))

          setTimeout(() => {
            dispatch(uploadStatusSlice.actions.updateUpload({
              isUploading: false,
              isCompletet: false,
              isFail: false,
              barWidth: "w-[0%]"
            }))
          }, 3000);
        }

      };

      contract();

    } catch (error) {
      setUploadWait(false);
      setUploadFail(true);
      setCurrentFileName("")
      setTimeout(() => {
        setUploadFail(false);
      }, 3000);
      dispatch(uploadStatusSlice.actions.updateUpload({
        isUploading: false,
        isCompletet: false,
        isFail: true,
        barWidth: "w-[0%]"
      }))

      setTimeout(() => {
        dispatch(uploadStatusSlice.actions.updateUpload({
          isUploading: false,
          isCompletet: false,
          isFail: false,
          barWidth: "w-[0%]"
        }))
      }, 3000);
    }

  };


  // final upload file details 
  const finalUpload = async (fullfileSizes, acc, fileCount) => {

    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + `use-space`, {
        method: "POST",
        body: JSON.stringify({
          "BatchSize": fullfileSizes,
          "DID": did,
          "Owner": acc,
        })
      })

      if (res.status === 200) {

        // for file point adding 
        await addPoints(fileCount)

        // changing uploading stats
        setCurrentFileName("")

        dispatch(uploadStatusSlice.actions.updateUpload({
          isUploading: false,
          isCompletet: true,
          isFail: false,
          barWidth: "w-[0%]",
          msg: ""
        }))

        // reset state after uploading 
        setTimeout(() => {
          dispatch(uploadStatusSlice.actions.updateUpload({
            isUploading: false,
            isCompletet: false,
            isFail: false,
            barWidth: "w-[0%]",
            msg: ""

          }))
        }, 3000);

        setTimeout(() => {
          setUploadWait(false);
          setUploadSuccess(true);
        }, 3000);


        childRefFunc();

        setTimeout(() => {
          setUploadFail(false);
          setUploadSuccess(false);
        }, 3000);
      }else{
        dispatch(uploadStatusSlice.actions.updateUpload({
          isUploading: false,
          isCompletet: true,
          isFail: false,
          barWidth: "w-[0%]",
          msg: ""
        }))
      }
    } catch (error) {
      console.log("Error:", error);
      setUploadWait(false);
      setCurrentFileName("")
      setTimeout(() => {
        setUploadFail(false);
      }, 3000);


      dispatch(uploadStatusSlice.actions.updateUpload({
        isUploading: false,
        isCompletet: false,
        isFail: true,
        barWidth: "w-[0%]",
        msg: ""
      }))

      setTimeout(() => {
        dispatch(uploadStatusSlice.actions.updateUpload({
          isUploading: false,
          isCompletet: false,
          isFail: false,
          barWidth: "w-[0%]",
          msg: ""
        }))
      }, 1000);
    }

  }

  // hide and show 
  const [toggleUploadContent, setToggleUploadContent] = useState(false)

  // show and hide pop up upload button content
  const showContent = () => {
    setToggleUploadContent(true)
  }

  const hideContent = () => {
    setTimeout(() => {
      setToggleUploadContent(false)
    }, 1000);

  }

  const navigateToPlan = () => {
    navigate("/dashboard/billing")
  }

  // add points into the plans 
  const addPoints = async (fileCount) => {
    console.log(fileCount)
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + "file-points", {
        method: "POST",
        body: JSON.stringify({
          "DID": did,
          "FileCount": fileCount
        })
      })

      if (res.status === 200) {

        const points = await res.json()
        console.log(points)

        setTimeout(() => {
          dispatch((dispatch, getState) => {
            dispatch(filePointsSlice.actions.savePoints({
              points: points.AddedPoints,
              is_added: true,
            }))
          })
        }, 4000);

        // reset store data
        setTimeout(() => {
          dispatch((dispatch, getState) => {
            dispatch(filePointsSlice.actions.savePoints({
              points: 0,
              is_added: false,
            }))
          })
        }, 7000);

        getTotalPoints()

      }
    } catch (error) {

      setUploadWait(false);
      setCurrentFileName("")
      setTimeout(() => {
        setUploadFail(false);
      }, 3000);


      dispatch(uploadStatusSlice.actions.updateUpload({
        isUploading: false,
        isCompletet: false,
        isFail: true,
        barWidth: "w-[0%]",
        msg: ""
      }))

      setTimeout(() => {
        dispatch(uploadStatusSlice.actions.updateUpload({
          isUploading: false,
          isCompletet: false,
          isFail: false,
          barWidth: "w-[0%]",
          msg: ""
        }))
      }, 1000);

    }
  }

  const planStats = useSelector((state) => state.planStatusReducer)
  const planExpired = useSelector((state) => state.planStatusReducer)

  // get total points 
  const getTotalPoints = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-counts", {
        method: "POST",
        body: JSON.stringify({
          "DID": did
        })
      })

      const totalPoints = await res.json()
      console.log(totalPoints)
      dispatch(totalPointsSlice.actions.saveTotalPoints({totalPoints : totalPoints.Points}))

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <section className={`flex ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale blur-sm"}`}>

      {/* user adding funcntion robot status */}
      {planExpired.planExpire && <NavLink to="/dashboard/billing">
        <div className="fixed z-[99999] right-8 bottom-[210px] w-[400px] inline-block text-sm text-white border border-gray-200 rounded-lg shadow-sm bg-red-400 animate__animated animate__fadeInRight animate__fast">
          {/* Header with MetaMask Logo */}
          <div className="px-3 py-2 bg-red-600 border-b border-gray-200 rounded-t-lg flex gap-2 items-center">
            <div className="p-2 rounded-full bg-white">
              <img src={iconExpired} className="w-[30px]" alt="" />
            </div>
            <h3 className="font-semibold text-white text-[16px]">Oops! Your plan duration was expired!</h3>
          </div>

          {/* Content */}
          <div className="px-3 py-4 flex flex-col">
            <h4 className='font-bold text-black mb-3'>Please Re-new your plan to activate account! </h4>
          </div>

          {/* Triangle Pointer */}
          <div className="absolute bottom-0 right-20 transform translate-y-full w-6 h-6 border-t-[30px] border-t-red-400 border-l-[30px] border-l-transparent"></div>
        </div>

        {/* div ai robot div */}
        <div className='z-[1000] fixed -bottom-10 -right-12 -rotate-6 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
          <img src={robot} className='w-[150px] opacity-100 ' alt="" />
        </div>
      </NavLink>}



      <div className="w-full">
        <div className={`flex ${blur} my-3`}>
          <div>
            <img
              src={iconStorage}
              alt=""
              className="w-[20px] inline-block text-white"
            />
          </div>

          <div className="ml-2 mb-2">
            {changeUploadMethod ? (
              <h3 className="text-white">FILES </h3>
            ) : (
              <h3 className="text-white">FILES / SINGLE FILE UPLOAD</h3>
            )}
          </div>
        </div>

        {/* upload method changes */}
        <div className={`flex items-center ${blur} gap-2 py-2`}>
          {/* <div> */}
          {/* button div */}
          {/* <div className="toggle ml-3 py-2">
              <input type="checkbox" id="btn" checked={changeUploadMethod} onChange={changeUploadMethods}/>
              <label for="btn">
                <span className="thumb"></span>
              </label>
              <div className="light"></div>
            </div> */}
          {/* end of the button */}
          {/* </div> */}

          {/* {!changeUploadMethod ? (<div className='text-white ml-4 font-bold '>
            <h3>Switch to Batch File Upload</h3>
          </div>) : (
            <div className='text-bethel-green ml-4 font-bold '>
              <h3>Switch to Single File Upload</h3>
            </div>
          )} */}
        </div>
        {/* upload method changes */}

        {/* select item for the single file uploads ------------------------------- */}
        {!changeUploadMethod && (
          <div className={`${blur} flex px-3 pt-3`}>
            <div className="flex flex-row px-6 py-2 mr-3 text-white rounded-md bg-gray-100/20 gap-x-8">
              <div className="flex flex-col items-center">
                <h3 className="text-white text-[14px] font-bold">
                  {dashboardDetails ? (
                    dashboardDetails && dashboardDetails.size
                  ) : (
                    <div>0</div>
                  )}
                </h3>
                <h3 className="text-center uppercase text-[10px] ">Files</h3>
              </div>

              <div>
                <h3 className="text-white text-[14px] font-bold">
                  {dashboardDetails && dashboardDetails.mb} MiB
                </h3>
                <h3 className="text-[10px] text-center uppercase">Usage</h3>
              </div>

              <input
                onChange={handleFileUpload}
                id="file-type"
                type="file"
                hidden
              />
              <label
                for="file-type"
                id="button"
                className="cursor-pointer flex flex-row items-center justify-center"
              >
                <div className="flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col">
                  <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                    <div className=" bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                    <div className="relative w-56 h-12 mr-4  bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                      <div className="  w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent hover:text-white rounded-md">
                        <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                          <h3>Select Items</h3>
                        </span>
                      </div>
                    </div>
                    {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                  </div>
                </div>
              </label>
            </div>
          </div>
        )}

        {/* Show Import Items */}
        {!changeUploadMethod && (
          <div class={`${blur} relative after:overflow-x-auto mt-6 px-2`}>
            <table class="table-auto border-collapse w-full">
              <thead className="relative">
                <tr class="rounded-lg text-sm font-medium text-left flex justify-between bg-gray-800/20">
                  <th class="px-4 py-2  text-gray-300">Selected Item :</th>
                  <th class="px-4 py-2  text-gray-300 flex flex-row gap-x-4"></th>
                  {uploadWait && <div class="loader"></div>}
                </tr>
              </thead>

              <tbody class="text-sm font-normal text-gray-400">
                <tr class=" py-10">
                  {fileName && (
                    <td class="px-4 py-4 flex justify-between">
                      <div>{fileName}</div>

                      <div className="flex gap-4">
                        <div>
                          <h1>{(fileSize / 1024 / 1024).toFixed(5)} Mib</h1>
                        </div>

                        <div className="gap-2 flex items-center">
                          <svg
                            viewBox="0 0 20 15"
                            className="w-6 h-6 cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                fill="#ffffff"
                                fill-rule="evenodd"
                                d="M9 12a1 1 0 102 0V4.26l3.827 3.48a1 1 0 001.346-1.48l-5.5-5a1 1 0 00-1.346 0l-5.5 5a1 1 0 101.346 1.48L9 4.26V12zm-5.895-.796A1 1 0 001.5 12v3.867a2.018 2.018 0 002.227 2.002c1.424-.147 3.96-.369 6.273-.369 2.386 0 5.248.236 6.795.383a2.013 2.013 0 002.205-2V12a1 1 0 10-2 0v3.884l-13.895-4.68zm0 0L2.5 11l.605.204zm0 0l13.892 4.683a.019.019 0 01-.007.005h-.006c-1.558-.148-4.499-.392-6.984-.392-2.416 0-5.034.23-6.478.38h-.009a.026.026 0 01-.013-.011V12a.998.998 0 00-.394-.796z"
                              ></path>{" "}
                            </g>
                          </svg>
                          <button
                            onClick={upload}
                            className="cursor-pointer flex relative w-full  bg-gradient-to-r from-bethel-orange to- to-bethel-newgreen xxxs:w-full hover:text-white px-3 py-1 hover:bg-black rounded-md"
                          >
                            <div className="  w-full rounded-md">
                              <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                                Batch Upload
                              </span>
                            </div>
                          </button>

                          <button
                            onClick={upload}
                            className="cursor-pointer flex relative w-full  bg-gradient-to-r from-bethel-orange to- to-bethel-newgreen xxxs:w-full hover:text-white px-3 py-1 hover:bg-black rounded-md"
                          >
                            <div className="  w-full rounded-md">
                              <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                                Single Upload
                              </span>
                            </div>
                          </button>

                          <button onClick={deleteUpload}>
                            <svg
                              fill="#ffffff"
                              viewBox="0 0 32 32"
                              className="w-4 h-4 cursor-pointer"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#ffffff"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                strokeLinejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <path d="M18.8,16l5.5-5.5c0.8-0.8,0.8-2,0-2.8l0,0C24,7.3,23.5,7,23,7c-0.5,0-1,0.2-1.4,0.6L16,13.2l-5.5-5.5 c-0.8-0.8-2.1-0.8-2.8,0C7.3,8,7,8.5,7,9.1s0.2,1,0.6,1.4l5.5,5.5l-5.5,5.5C7.3,21.9,7,22.4,7,23c0,0.5,0.2,1,0.6,1.4 C8,24.8,8.5,25,9,25c0.5,0,1-0.2,1.4-0.6l5.5-5.5l5.5,5.5c0.8,0.8,2.1,0.8,2.8,0c0.8-0.8,0.8-2.1,0-2.8L18.8,16z"></path>{" "}
                              </g>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>

            {/* upload success and fail  */}
            {!uploadSuccess && (
              <div
                className=" w-[200px] bg-black/20 border-bethel-green border-[1px]  h-[40px] rounded-sm absolute
              top-[20px] right-2 py-2 flex-col flex popup items-center justify-center text-white"
                id="popup"
              >
                <div className="flex flex-row items-center justify-center gap-x-2">
                  <button className="rounded-full shadow-md">
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      className="w-5 "
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          stroke="#aaff00"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          strokeLinejoin="round"
                        ></path>{" "}
                        <path
                          d="M7.75 12L10.58 14.83L16.25 9.17004"
                          stroke="#aaff00"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          strokeLinejoin="round"
                        ></path>{" "}
                      </g>
                    </svg>
                  </button>
                  <h1 className="text-sm font-sm text-bethel-green ">
                    Upload Success
                  </h1>
                </div>
              </div>
            )}

            {uploadFail && (
              <div
                className=" w-[200px] bg-black/20 border-red-500 border-[1px]  h-[40px] rounded-sm absolute
              top-[-50px] right-2 py-2 flex-col flex popup items-center justify-center text-red-400"
                id="popup"
              >
                <div className="flex flex-row items-center justify-center gap-x-2">
                  <button className="rounded-full shadow-md">
                    <img src={iconWrong} alt="" className="w-[20px]" />
                  </button>
                  <h1 className="text-sm font-sm text-red-500">Upload Fail</h1>
                </div>
              </div>
            )}
            {/* end of upload success msg */}
          </div>
        )}
        {/* end of the item for the single file uploads ------------------------------- */}

        {/* select item for the multiple file uploads ------------------------------- */}
        {changeUploadMethod && (
          <div className={`${blur} flex px-3 pt-3 `}>
            <div className="flex lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col px-6 py-2 mr-3 text-white rounded-md bg-gray-100/20 gap-x-8 gap-y-4 items-center  justify-between">
              <div className="flex items-center justify-center gap-2">

                <div className="relative flex flex-col w-full justify-center items-center">
                  <h3 className="text-white text-[14px] font-bold">{filesCount && (filesCount / 1000000).toFixed(2)} MiB</h3>
                  <h3 className="text-[10px] text-center uppercase">Usage</h3>
                </div>
              </div>

              <div>
                <input disabled={planExpired.planExpire}
                  onChange={handleBulkUpload}
                  id="file-types"
                  type="file"
                  multiple
                  hidden
                />
                <label
                  for="file-types"
                  id="button"
                  className="cursor-pointer flex flex-row items-center justify-center"
                >
                  {!planExpired.planExpire &&
                    <div onMouseEnter={showContent} onMouseLeave={hideContent} className="flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col relative">
                      <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                        <div className=" bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                        <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                          <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent hover:text-white rounded-md">
                            <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                              <h3>Select Files</h3>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>}
                </label>


                {planExpired.planExpire &&
                  <button onClick={navigateToPlan} onMouseEnter={showContent} onMouseLeave={hideContent} className="flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col relative">
                    <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                      <div className=" bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                      <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                        <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent hover:text-white rounded-md">
                          <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                            <h3>Select a Plan</h3>

                            {(planExpired.planExpire && toggleUploadContent) &&
                              <div onMouseLeave={hideContent} className="animate__animated animate__fadeIn absolute top-[-70px] w-[200px] bg-white/10 font-normal p-2 rounded-lg flex justify-center text-center">
                                <h3 className="text-white text-[10px]">Please select a plan to continue uploading. Click <button onClick={() => navigate("/dashboard/billing")}><span className="text-bethel-green"><i>here</i></span></button> to choose plan</h3>
                                <div class="absolute w-0 h-0 border-t-8 border-b-8 border-l-8 border-transparent border-l-white/10 rotate-90 bottom-[-12px]"></div>
                              </div>}
                          </span>
                        </div>
                      </div>
                    </div>
                  </button>}
              </div>
            </div>

            {/* buy mint for the login  */}
          </div>
        )}

        {/* Show Import Items */}
        {changeUploadMethod && (
          <div class={`${blur}  relative after:overflow-x-auto mt-6 px-2 ${planStats.planExpire ? "grayscale pointer-events-none" : ""}`}>
            <table class="table-auto border-collapse w-full">
              <thead className="relative">
                <tr class="rounded-lg text-sm font-medium text-left flex justify-between bg-gray-800/20 py-2">
                  <th class="px-4 py-2  text-gray-300">Selected Items :</th>
                  <th class="px-4 py-2  text-gray-300 flex flex-row gap-x-4"></th>
                </tr>
              </thead>

              <tbody class="text-sm font-normal text-gray-400">
                {bulkUploadsLoop &&
                  bulkUploadsLoop.map((uploadItem) => {
                    return (
                      <tr class=" py-1">
                        <div>
                          <td class="px-4 py-4 flex justify-between">
                            <div>{uploadItem.name}</div>

                            <div className="flex gap-4">
                              <div>
                                <h1>{((uploadItem.size).toString().length > 5) ? (uploadItem.size / 1000000).toFixed(2) + " MiB" : (uploadItem.size / 1000).toFixed(2) + " KiB"}</h1>
                              </div>

                              <div className="gap-2 flex items-center"></div>
                            </div>
                          </td>
                        </div>
                      </tr>
                    );
                  })}

                {uploadBtnShow && (
                  <div>
                    {!singleOrBatch ? (
                      <button
                        onClick={bulkUpload}
                        className="cursor-pointer flex absolute top-[-40px] right-3 w-[80px]  bg-gradient-to-r from-bethel-orange to- to-bethel-newgreen xxxs:w-full hover:text-white px-3 py-1 hover:bg-black rounded-md"
                      >
                        <div className="  w-full">
                          <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                            Upload
                          </span>
                        </div>
                      </button>
                    ) : (
                      <button
                        onClick={bulkUpload}
                        className="cursor-pointer flex absolute top-[-40px] right-3 w-[80px]  bg-gradient-to-r from-bethel-orange to- to-bethel-newgreen xxxs:w-full hover:text-white px-3 py-1 hover:bg-black rounded-md"
                      >
                        <div className="w-full">
                          <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                            Upload
                          </span>
                        </div>
                      </button>
                    )}
                  </div>
                )}

                {uploadBtnShow && (
                  <button
                    onClick={deleteBulkuploads}
                    className="rounded-md cursor-pointer flex absolute top-[-40px] w-[80px] right-24  bg-red-600 xxxs:w-full hover:text-white px-3 py-1"
                  >
                    <div className="  w-full ">
                      <span className=" flex items-center justify-center font-bold text-black hover:text-white font-raleway">
                        Clear
                      </span>
                    </div>
                  </button>
                )}
              </tbody>
            </table>

            {/* upload success and fail  */}
            {uploadSuccess && (
              <div
                className=" w-[200px] rounded-sm absolute 
              top-[10px] right-2 py-2 flex-col flex popup items-center justify-center text-white"
                id="popup"
              >
                <div className="flex flex-row items-center justify-center gap-x-2">
                  <button className="rounded-full shadow-md">
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      className="w-5 "
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          stroke="#aaff00"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          strokeLinejoin="round"
                        ></path>{" "}
                        <path
                          d="M7.75 12L10.58 14.83L16.25 9.17004"
                          stroke="#aaff00"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          strokeLinejoin="round"
                        ></path>{" "}
                      </g>
                    </svg>
                  </button>
                  <h1 className="text-sm font-sm text-bethel-green font-bold">
                    Upload Complete
                  </h1>
                </div>
              </div>
            )}

            {uploadFail && (
              <div
                className=" w-[200px] bg-black/20 border-red-500 border-[1px]  h-[40px] rounded-sm absolute
              top-[-50px] right-2 py-2 flex-col flex popup items-center justify-center text-red-400"
                id="popup"
              >
                <div className="flex flex-row items-center justify-center gap-x-2">
                  <button className="rounded-full shadow-md">
                    <img src={iconWrong} alt="" className="w-[20px]" />
                  </button>
                  <h1 className="text-sm font-sm text-red-500">Upload Fail</h1>
                </div>
              </div>
            )}

            {/* max upload limit exceed */}
            {maxUploadLimit && (
              <div
                className=" w-[250px] bg-black/20 border-red-500 border-[1px]  h-[40px] rounded-sm absolute
              top-[-50px] right-2 py-2 flex-col flex popup items-center justify-center text-red-400"
                id="popup"
              >
                <div className="flex flex-row items-center justify-center gap-x-2">
                  <button className="rounded-full shadow-md">
                    <img src={iconWrong} alt="" className="w-[20px]" />
                  </button>
                  <h1 className="text-[12px] font-xs text-red-500 ">Upload Limit exceeded! <br /> ( max is 40 files )</h1>
                </div>
              </div>
            )}
            {/* end of upload success msg */}
          </div>
        )}
        {/* end of the item for the single file uploads ------------------------------- */}

        <div className="flex w-full lg:flex-row md:flex-col sm:flex-col min-[320px]:flex-col">
          <div className="flex basis-1/2  w-full">
            <TableWithMoreButton
              changeUploadMethod={changeUploadMethod}
              ref={childRef}
            />
          </div>

          <div className={`flex basis-1/2 w-full ${planStats.planExpire ? "grayscale pointer-events-none" : ""}`}>
            <FileSharedComp />
          </div>
        </div>
      </div>
    </section>
  );
}
