import React, { useEffect, useRef, useState } from 'react'
import iconWifi from "../Images/icons/icons8-wifi-100.png"
import iconStorage from "../Images/icons/icon-storage.png"
import iconFile from "../Images/icons/icon-file.png"
import iconHome from "../Images/icons/icon-home.png"
import iconFolder from "../Images/icons/icons-folder.png"
import { useDispatch, useSelector } from 'react-redux'
import { ethers } from 'ethers'
import SamllCard from './Cards/SamllCard'
import NetworkCard from './Cards/NetworkCard'
import CardTable from './Cards/CardTable'
import tableUpload from '../Images/icons/icon-Table-upload.png'
import PieChartCard from './Cards/PieChartCard'
import iconStatus from "../Images/icons/icon-status.png"
import { useNavigate } from 'react-router-dom'
import requireAvalibilitySlice from '../reducers/RequireAvalibilySlice'
import metamaskLogo from "../Images/icons/metamask.png"
import iconMedal1 from "../Images/icons/icon-medal1.png"
import iconMedal2 from "../Images/icons/icon-medal2.png"
import iconMedal3 from "../Images/icons/icon-medal3.png"
import iconMedal from "../Images/icons/icon-trophie.png"
import iconMedalNone from "../Images/icons/icon-medalnone.png"
import bethelLogo from "../Images/icons/bethel-logo.png"
import { a } from '@react-spring/three'


function DashboardHome() {
  const navigate = useNavigate()
  const [dashboardDetails, setDashboardDetails] = useState(null)
  const fileStorageAddress = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS;  // contract address
  const fileStorageJSON = require("../json/FileStorage.json");

  const dispatch = useDispatch()

  const fileStorageABI = fileStorageJSON.abi;
  const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)

  const [usedSpace, setUsedSpace] = useState(null) // used space state

  // get user details 
  const userDid = useSelector((state) => state.DidReducer)

  // current package currentPackage
  const [currentPackage, setCurrentPackage] = useState("")

  // if usre is here but not metamask here 
  const [noMetamask, setNoMetamask] = useState(false)

  const getFileDetails = async () => {

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const address = await signer.getAddress()

      const contract = new ethers.Contract(fileStorageAddress, fileStorageABI, signer)

      const fileStorageJSON2 = require("../json/FileStorage2.json");
      const fileStorageAddress2 = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS_2
      const fileStorageABI2 = fileStorageJSON2.abi

      const contract2 = new ethers.Contract(fileStorageAddress2, fileStorageABI2, signer)

      // get storage details 
      getStorageDetails(contract2, address)

      const res = await contract.getTotalFilesCount()
      setDashboardDetails(res)
    } catch (error) {
      console.log("This is error :", error)
      // navigate("/")
    }
  }

  // get dashboard storage details
  const getStorageDetails = async (contract, address) => {
    try {
      const current_package = await contract.getCurrentPackage(userDid, address)
      setCurrentPackage(current_package._packageName)

      const used_space = await contract.getUsedSpace(userDid)
      setUsedSpace(parseInt(used_space._hex))
    } catch (error) {
      console.log(error)
    }

  }

  // file details 
  const [fileNames, setFileNames] = useState({})


  useEffect(() => {
    userVerification()

    if (!window.ethereum) {
      dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, useruserAdded: false, noUser: false, allreadyRegisteredWallet: false }))
      return
    }
    getFileDetails();
    get_leaderboard_details() // get leaderboard details
    get_my_rank() //get my rank

  }, [])

  // is user verifeid or not 
  const userVerification = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + "wallets", {
        method: "POST",
        body: JSON.stringify({
          "DID": userDid
        })
      })
      const userVerificationResults = await res.json()

      if ((userVerificationResults && !window.ethereum)) {
        setNoMetamask(true)
      }

    } catch (e) {
      console.log(e)
    }


  }

  //get learderboard details
  const [leaderboarder_details, setLeaderboard_details] = useState(null)
  const [my_rank, setMy_rank] = useState(null)
  const rankRef = useRef()

  const [toggle_leader_board, setToggle_leader_board] = useState(false)

  const get_leaderboard_details = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-top-10-ref")
      if (res.ok) {
        const data = await res.json()
        setLeaderboard_details(data.TopReferrals)
      }
    } catch (error) {
      console.log("error in leaderboard details :", error)
    }
  }

  const get_my_rank = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-user-ref-rank", {
        method: "POST",
        body: JSON.stringify({
          "DID": userDid
        })
      })
      if (res.ok) {
        const data = await res.json()
        console.log(data)
        setMy_rank(data.Rank)
      }
    } catch (error) {
      console.log("error in leaderboard details :", error)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (rankRef.current && !rankRef.current.contains(event.target)) {
      setToggle_leader_board(false);
    }
  };

  return (
    <div className='w-full '>
      <div className={`w-full ${noMetamask ? "pointer-events-none grayscale" : ""}  text-white animate__animated animate__fadeIn ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none"}`}>

        {/* start of leaderboard div */}
        {toggle_leader_board &&
          <div ref={rankRef} className="flex flex-col gap-2 fixed bottom-0 w-[500px] bg-black rounded-t-lg border border-bethel-green/20 right-2 p-3 z-[200] animate__animated animate__fadeInUp animate__faster">

            {/* leaderboard */}
            <div className='w-full p-1 flex flex-col gap-2 z-[200] relative'>
              {/* top section */}
              <div className="flex gap-2 items-center justify-between tracking-wide p-2">
                <div className="flex items-center gap-2 ">
                  <div>
                    <img src={bethelLogo} className="w-[20px]" alt="" />
                  </div>
                  <div className="font-bold text-bethel-green text-[1rem]">
                    <h3>BETHEL LEADERBOARD</h3>
                  </div>
                </div>


                <div className="text-white font-bold text-[10px] flex gap-1 items-center">
                  <h3>Your Rank :</h3>
                  <h3 className="relative text-bethel-green  z-10 font-bold rounded-full w-[25px] h-[25px] justify-center items-center flex text-[10px] border border-bethel-green">{my_rank && my_rank}</h3>

                </div>
              </div>
              {/* end of top section */}

              <hr className="h-[1px] opacity-20 w-full" />

              <div className="flex w-full flex-col gap-2">
                {leaderboarder_details && leaderboarder_details.slice(0, 3).map((ref, index) => {
                  return <div className="flex flex-col">
                    <div className="flex items-center px-2 py-1 gap-2">

                      <div className="flex items-center w-full gap-2 ">
                        {/* tropie image */}
                        <div className={`flex`}>
                          {index === 0 && <img src={iconMedal1} className="w-[35px] animate-pulse" alt="" />}
                          {index === 1 && <img src={iconMedal2} className="w-[35px] opacity-80" alt="" />}
                          {index === 2 && <img src={iconMedal3} className="w-[35px] opacity-60" alt="" />}
                        </div>

                        {/* refs */}
                        {index === 0 && (
                          <div className="relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between animate-pulse">
                            <div className="bg-gradient-to-r from-green-600/40 via-green-400/40 to-transparent absolute inset-0 animate-fade-green"></div>
                            <h3 className="relative text-white/70 text-[14px] z-10">{ref.ReferralID}</h3>
                            <h3 className="relative text-white/70  z-10 font-bold rounded-full w-[25px] h-[25px] justify-center items-center flex text-[10px] border border-white/50">{ref.TotalPoint}</h3>
                          </div>
                        )}

                        {index === 1 && (
                          <div className="relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between">
                            <div className="bg-gradient-to-r from-yellow-600/40 via-yellow-400/40 to-transparent absolute inset-0 animate-fade-green"></div>
                            <h3 className="relative text-white/70 text-[14px] z-10">{ref.ReferralID}</h3>
                            <h3 className="relative text-white/70  z-10 font-bold rounded-full w-[25px] h-[25px] justify-center items-center flex text-[10px] border border-white/50">{ref.TotalPoint}</h3>

                          </div>
                        )}

                        {index === 2 && (
                          <div className="relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between">
                            <div className="bg-gradient-to-r from-orange-600/40 via-orange-400/40 to-transparent absolute inset-0 animate-fade-green"></div>
                            <h3 className="relative text-white/70 text-[14px] z-10">{ref.ReferralID}</h3>
                            <h3 className="relative text-white/70  z-10 font-bold rounded-full w-[25px] h-[25px] justify-center items-center flex text-[10px] border border-white/50">{ref.TotalPoint}</h3>

                          </div>
                        )}
                      </div>

                    </div>

                  </div>
                })}

                {leaderboarder_details && leaderboarder_details.slice(3, 10).map((ref, index) => {
                  return <div className="flex flex-col">
                    <div className="flex items-center px-2 py-1 gap-2">

                      <div className="flex items-center w-full gap-2 ">
                        {/* tropie image */}
                        <div className={`flex`}>
                          <img src={iconMedalNone} className="w-[35px]" alt="" />
                        </div>

                        <div className="relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between">
                          <div className="bg-gradient-to-r from-white/10 via-white/10 to-transparent absolute inset-0 animate-fade-green"></div>
                          <h3 className="relative text-white/70 text-[14px] z-10">{ref.ReferralID}</h3>
                          <h3 className="relative text-white/70  z-10 font-bold rounded-full w-[25px] h-[25px] justify-center items-center flex text-[10px] border border-white/50">{ref.TotalPoint}</h3>
                        </div>
                      </div>

                    </div>

                  </div>
                })}
              </div>
            </div>

            {/* toggle div */}
            <button onClick={() => setToggle_leader_board(!toggle_leader_board)} className='flex gap-1 p-2 absolute top-[-45px] w-fit items-center justify-center bg-black rounded-t-lg border-t border-l border-r border-bethel-green/20 right-[-1px] -z-10 px-4'>
              <div className='flex'>
                <img src={iconMedal} className='w-[30px] animate__animated animate__tada animate__infinite animate__slower' alt="" />
              </div>
              <div className='text-bethel-green/80 text-[12px] font-bold animate__animated animate__tada animate__infinite animate__slower'>
                <h3>Leaderbord</h3>
              </div>
            </button>

          </div>
        }
        {/* end of leader board div */}

        {!toggle_leader_board &&
          <button onClick={() => setToggle_leader_board(true)} className='flex gap-1 p-2 fixed bottom-0 w-fit items-center justify-center bg-black rounded-t-lg border border-bethel-green/20 right-4 z-[200] px-4'>
            <div className='flex'>
              <img src={iconMedal} className='w-[30px] animate__animated animate__tada animate__infinite animate__slower' alt="" />
            </div>
            <div className='text-bethel-green/80 text-[12px] font-bold animate__animated animate__tada animate__infinite animate__slower'>
              <h3>Leaderbord</h3>
            </div>
          </button>
        }

        {/* topic container */}
        <div className='flex mt-3 mb-5'>
          <div className=''>
            <img src={iconHome} alt="" className='w-[20px] inline-block -mt-1' />
          </div>

          <div className='ml-2'>
            <h3>DASHBOARD</h3>
          </div>
        </div>


        {/* main container div */}
        <div className='flex w-full h-full border-white pl-2 pr-3 
      lg:flex-nowrap md:flex-wrap sm:flex-wrap min-[320px]:flex-wrap'>

          {/* three card compoents and network stats and table div*/}
          <div className={`flex flex-col p-2 gap-5 lg:w-2/3 md:w-full sm:w-full min-[320px]:w-full ${!avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser && !avalibilityData.allreadyRegisteredWallet ? "" : "grayscale blur-sm"}`}>

            {/* three cards div */}
            <div className='flex w-full gap-5 lg:flex-nowrap md:flex-wrap sm:flex-wrap min-[320px]:flex-wrap'>
              <SamllCard card_icon={iconFolder} heading={'Folders'} subheading={'Total Folders :'} card_data={"1"} />
              <SamllCard card_icon={iconFile} heading={'Files'} subheading={'Total Files :'} card_data={dashboardDetails && parseInt(dashboardDetails._filesCount._hex)} />
              <SamllCard card_icon={iconStorage} heading={'Storage Plan'} subheading={'Status :'}
                card_data={`${usedSpace && (usedSpace && usedSpace / 1000000).toFixed(2)} MB / ${currentPackage && currentPackage === "Starter" ? "1 TB" : ""} ${currentPackage && currentPackage === "Basic" ? "1 GB" : ""} ${currentPackage && currentPackage === "Advance" ? "5 TB" : ""}`} />
            </div>

            {/* netwoek section */}
            <div className='h-auto w-full'>
              <NetworkCard card_heading={"Network Usage"} card_image={iconWifi} Card_subheading={"Total Network Usage :"} />
            </div>

            {/* table content */}
            <div className='h-auto w-full'>
              <CardTable NameArr={fileNames} iconCard={tableUpload} iconFile={iconFile} heading={"Recent Uploads"} />
            </div>
          </div>

          {/* chart div with pie chart and files music and  other section */}
          <div className={`flex  p-1 h-auto lg:w-1/3 md:w-full sm:w-full min-[320px]:w-full ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "grayscale blur-sm"}`}>
            <PieChartCard card_icon={iconStatus} card_heading={'Status'} card_subHeading={"Chart Details :"} UsedValue={usedSpace && (usedSpace && usedSpace / 1000000).toFixed(3)} FilesCount={dashboardDetails && parseInt(dashboardDetails._filesCount._hex)}
              currentPackage={currentPackage} />
          </div>

        </div>
      </div>

      {/* meta mask not install warning */}
      {noMetamask &&
        <a href="https://metamask.io/download/" target='_blank' rel='noreferrer'><div className="fixed bottom-4 right-4 px-5 py-2 bg-black border-[1px] border-red-500/40 rounded-lg flex gap-2 items-center">

          <div className="p-2 rounded-full bg-black border-red-500 border-[1px] z-[100]">
            <img src={metamaskLogo} className="w-[20px]" alt="" />
          </div>
          <div className='flex flex-col'>
            <h3 className="font-semibold text-[18px] text-red-500 ">Meta mask is not Detected! Please Install Metamask</h3>
            <h3 className='text-[14px] text-white/50'>(Please Click this Box to Download Meta Mask)</h3>
          </div>



        </div>
        </a>
      }
    </div>

  )
}

export default DashboardHome
